<template>
  <div>
    <h1>Admin Utility</h1>
    <p>Sync, reusable seeders, cache and MISC</p>

    <h3 class="mt-4 mb-2">
      Cache Clear
    </h3>
    <div>When you suspect the distributor results got cached, e.g. product Selection</div>
    <v-btn
      :loading="loading"
      @click="doUtility('cache-clear')"
    >
      Clear
    </v-btn>

    <h3 class="mt-4 mb-2">
      Sync Merchant settings
    </h3>
    <div>
      When added new merchant settings
    </div>
    <v-btn
      :loading="loading"
      @click="doUtility('seed-merchant-setting')"
    >
      Seed Merchant Setting
    </v-btn>

    <h3 class="mt-4 mb-2">
      Sync Webhook Types
    </h3>
    <div>
      When added new webhook types (e.g. ORDER_SUCCEEDED)
    </div>
    <v-btn
      :loading="loading"
      @click="doUtility('seed-webhook-type')"
    >
      Seed Webhook Types
    </v-btn>

    <h3 class="mt-4 mb-2">
      Added Channel Credentials
    </h3>
    <div>
      When added new channel credentials (e.g. blue azure_sas for filesystems)
    </div>
    <v-btn
      :loading="loading"
      @click="doUtility('seed-channel-credential')"
    >
      Seed Channel Credentials
    </v-btn>

    <h3 class="mt-4 mb-2">
      Added Country
    </h3>
    <div>
      When added new country & currency. Note: you also need to press "seed country" from Backend.
    </div>
    <v-btn
      :loading="loading"
      @click="doUtility('seed-country')"
    >
      Seed Country
    </v-btn>
    <v-btn
      :loading="loading"
      @click="doUtility('persist-latest-rate')"
    >
      Persist Latest Rate
    </v-btn>

    <h3 class="mt-4 mb-2">
      Added Selection
    </h3>
    <div>
      When added new selection
    </div>
    <v-btn
      :loading="loading"
      @click="doUtility('seed-selection')"
    >
      Seed Selection
    </v-btn>
  </div>
</template>

<script>
import { postUtility } from '@/api/admin'
import { useNotifyErrors, useNotifySuccess } from '@/composables'
import { ref } from '@vue/composition-api'

export default {
  setup() {
    const loading = ref(false)

    const doUtility = async route => {
      loading.value = true

      await postUtility(route)
        .then(() => useNotifySuccess({ content: route }))
        .catch(useNotifyErrors)
        .finally(() => { loading.value = false })
    }

    return {
      doUtility,
      loading,
    }
  },
}
</script>
